import i18n from "@/i18n";
import type { OcrolusTiles } from "@/models/commercialData/Ocrolus";

export const OCROLUS_BOOK_OPTION_BOTH = "" as const;
export const OCROLUS_BOOK_OPTION_BOTH_INSTANT = "both_instant" as const;
export const OCROLUS_BOOK_OPTION_PLAID = "plaid_json" as const;
export const OCROLUS_BOOK_OPTION_BANK_STATEMENTS = "bank_statements" as const;
export const OCROLUS_BOOK_OPTION_BANK_STATEMENTS_INSTANT =
  "bank_statements_instant" as const;

export const OCROLUS_BOOK_OPTIONS = {
  [OCROLUS_BOOK_OPTION_PLAID]: "Plaid JSON",
  [OCROLUS_BOOK_OPTION_BANK_STATEMENTS]: "PDF Bank Statements",
  [OCROLUS_BOOK_OPTION_BANK_STATEMENTS_INSTANT]: "PDF Bank Statements Instant",
  [OCROLUS_BOOK_OPTION_BOTH]: "Both (JSON + PDF)",
  [OCROLUS_BOOK_OPTION_BOTH_INSTANT]: "Both (JSON + PDF Instant)"
} as const;

type OcrolusDisplayCard = {
  key?: keyof OcrolusTiles;
  keys?: (keyof OcrolusTiles)[];
  title: string;
};

export const DISPLAY_CARDS: OcrolusDisplayCard[] = [
  {
    key: "average_alt_lender_payment",
    title: i18n.global.t(
      "DEALS.DEAL_PROGRESS.UNDERWRITING.OCROLUS.AVG_ALT_LENDER_PAYMENT"
    )
  },
  {
    key: "average_merchant_cash_advance_payment",
    title: i18n.global.t("DEALS.DEAL_PROGRESS.UNDERWRITING.OCROLUS.AVG_MCA")
  },
  {
    key: "avg_estimated_monthly_revenue",
    title: i18n.global.t(
      "DEALS.DEAL_PROGRESS.UNDERWRITING.OCROLUS.AVG_ESTIMATED_MONTHLY_REVENUE"
    )
  },
  {
    keys: ["average_daily_cash_flow", "average_daily_cash_flow_weekdays"],
    title: i18n.global.t(
      "DEALS.DEAL_PROGRESS.UNDERWRITING.OCROLUS.AVG_DAILY_CASH_FLOW"
    )
  }
];

export const DISPLAY_CARDS_V2: OcrolusDisplayCard[] = [
  {
    key: "num_fintech_mca_sources",
    title: i18n.global.t(
      "DEALS.DEAL_PROGRESS.UNDERWRITING.OCROLUS.NUM_FINTECH_MCA_SOURCES"
    )
  },
  {
    key: "num_fintech_loan_sources",
    title: i18n.global.t(
      "DEALS.DEAL_PROGRESS.UNDERWRITING.OCROLUS.NUM_FINTECH_LOAN_SOURCES"
    )
  },
  {
    keys: ["average_daily_balance", "average_daily_balance_weekday"],
    title: i18n.global.t(
      "DEALS.DEAL_PROGRESS.UNDERWRITING.OCROLUS.AVG_DAILY_BALANCE"
    )
  },
  {
    key: "average_daily_cash_flow",
    title: i18n.global.t(
      "DEALS.DEAL_PROGRESS.UNDERWRITING.OCROLUS.AVG_DAILY_CASH_FLOW"
    )
  }
];

type OcrolusTypeCard = {
  values: { key: keyof OcrolusTiles; label: string }[];
  title: string;
};

export const TREND_CARDS: OcrolusTypeCard[] = [
  {
    values: [
      {
        key: "cash_balance_trend_30",
        label: i18n.global.t("TEMPORAL.THIRTY_DAYS")
      },
      {
        key: "cash_balance_trend_60",
        label: i18n.global.t("TEMPORAL.SIXTY_DAYS")
      },
      {
        key: "cash_balance_trend_90",
        label: i18n.global.t("TEMPORAL.NINETY_DAYS")
      }
    ],
    title: i18n.global.t(
      "DEALS.DEAL_PROGRESS.UNDERWRITING.OCROLUS.CASH_BALANCE_TREND"
    )
  },
  {
    values: [
      {
        key: "cash_flow_trend_30",
        label: i18n.global.t("TEMPORAL.THIRTY_DAYS")
      },
      {
        key: "cash_flow_trend_60",
        label: i18n.global.t("TEMPORAL.SIXTY_DAYS")
      },
      {
        key: "cash_flow_trend_90",
        label: i18n.global.t("TEMPORAL.NINETY_DAYS")
      }
    ],
    title: i18n.global.t(
      "DEALS.DEAL_PROGRESS.UNDERWRITING.OCROLUS.CASH_FLOW_TREND"
    )
  }
];
