import type { IBopStatuses } from "@/models/commercialData/Experian";
export const BOP_INQUIRY_TYPES: Record<string, keyof IBopStatuses> = {
  experian_bop_blended_account_review: "blended_account_review",
  experian_bop_blended_prequalification: "blended_prequalification",
  experian_bop_commercial_collections: "commercial_collections",
  experian_bop_commercial_factoring: "commercial_factoring",
  experian_bop_commercial_insurance: "commercial_insurance",
  experian_bop_commercial_lending_to_a_sole_prop:
    "commercial_lending_to_a_sole_prop",
  experian_bop_commercial_lending_with_a_pg: "commercial_lending_with_a_pg",
  experian_bop_merchant_acquisition: "merchant_acquisition",
  experian_bop_merchant_cash_advance: "merchant_cash_advance"
} as const;
import i18n from "@/i18n";
import { SearchOption } from "@/enums/experian";

export const BOP_PREMIER_INQUIRY_TYPES: Record<string, keyof IBopStatuses> = {
  experian_bop_blended_account_review_premier_attributes:
    "blended_account_review_premier_attributes",
  experian_bop_blended_prequalification_premier_attributes:
    "blended_prequalification_premier_attributes",
  experian_bop_commercial_collections_premier_attributes:
    "commercial_collections_premier_attributes",
  experian_bop_commercial_factoring_premier_attributes:
    "commercial_factoring_premier_attributes",
  experian_bop_commercial_insurance_premier_attributes:
    "commercial_insurance_premier_attributes",
  experian_bop_commercial_lending_to_a_sole_prop_premier_attributes:
    "commercial_lending_to_a_sole_prop_premier_attributes",
  experian_bop_commercial_lending_with_a_pg_premier_attributes:
    "commercial_lending_with_a_pg_premier_attributes",
  experian_bop_merchant_acquisition_premier_attributes:
    "merchant_acquisition_premier_attributes",
  experian_bop_merchant_cash_advance_premier_attributes:
    "merchant_cash_advance_premier_attributes"
} as const;

export const BOP_PDF_REPORT_INQUIRY_TYPES: Record<string, keyof IBopStatuses> =
  {
    experian_bop_blended_account_review_pdf_report:
      "blended_account_review_pdf_report",
    experian_bop_blended_prequalification_pdf_report:
      "blended_prequalification_pdf_report",
    experian_bop_commercial_collections_pdf_report:
      "commercial_collections_pdf_report",
    experian_bop_commercial_factoring_pdf_report:
      "commercial_factoring_pdf_report",
    experian_bop_commercial_insurance_pdf_report:
      "commercial_insurance_pdf_report",
    experian_bop_commercial_lending_to_a_sole_prop_pdf_report:
      "commercial_lending_to_a_sole_prop_pdf_report",
    experian_bop_commercial_lending_with_a_pg_pdf_report:
      "commercial_lending_with_a_pg_pdf_report",
    experian_bop_merchant_acquisition_pdf_report:
      "merchant_acquisition_pdf_report",
    experian_bop_merchant_cash_advance_pdf_report:
      "merchant_cash_advance_pdf_report"
  } as const;

export const BOP_CONSUMER_SCORE_MODELS = {
  fico8: "FICO 8",
  fico9: "FICO 9",
  fico_advanced2: "FICO Advanced2",
  fico_v2: "FICO v2",
  vantage4: "Vantage 4.0"
} as const;

export const EXPERIAN_GDN_SUBSERVICES = {
  experian_gdn_company_profile: "experian_gdn_company_profile",
  experian_gdn_risk_check: "experian_gdn_risk_check",
  experian_gdn_small_report: "experian_gdn_small_report",
  experian_gdn_extended_report: "experian_gdn_extended_report",
  experian_gdn_canadian_profile_report: "experian_gdn_canadian_profile_report"
} as const;

export const EXPERIAN_GDN_SECTION_NAMES = {
  company_profile: "company_profile",
  risk_check: "risk_check",
  small_report: "small_report",
  extended_report: "extended_report"
} as const;

export const CLASS_RATING_TYPE = "class_rating";
export const RISK_CLASS_TYPE = "risk_class";

export const searchOptions: Record<SearchOption, string> = {
  [SearchOption.BUSINESS_NAME]: i18n.global.t(
    "DEALS.EXPERIAN_MATCH.BUSINESS_NAME"
  ),
  [SearchOption.CONTACT_NAME]: i18n.global.t(
    "DEALS.EXPERIAN_MATCH.CONTACT_NAME"
  ),
  [SearchOption.BUSINESS_PHONE]: i18n.global.t("COMMON.BUSINESS_PHONE"),
  [SearchOption.BIN]: i18n.global.t("DEALS.EXPERIAN_MATCH.BIN"),
  [SearchOption.BUSINESS_ADDRESS]: i18n.global.t(
    "DEALS.DEAL_PROGRESS.BUSINESS_INFO.ADDRESS"
  )
};
